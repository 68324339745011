<template>
    <page heading="Settings"></page>
</template>

<script>
import Page from '../layouts/Page.vue';

export default {
    name: 'Settings',
    components: { Page },
};
</script>
